import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Layout, theme, Modal, Input, Select, message, Button } from "antd";
import Sidebar from "./components/common/Sidebar";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import SitePage from "./pages/SitePage";
import DashboardVMSPage from "./pages/vms/DashboardPage";
import ProgramVMSPage from "./pages/vms/ProgramPage";
import ProgramPatrolPage from "./pages/patrol/ProgramPage";
import CyclePatrolPage from "./pages/patrol/CyclePatrolPage";
import SubContractVMSPage from "./pages/vms/SubContractPage";
import DeviceVMSPage from "./pages/DevicePage";
import DeviceNotiPage from "./pages/DeviceNotiPage";
import NotifyPage from "./pages/NotifyPage";
import ProfilePage from "./pages/ProfilePage";
import OnlyPC from "./pages/OnlyPC";

import IndexDocsPage from "./pages/docs/IndexPage";
import EmployeePage from "./pages/docs/EmployeePage";
import StockPage from "./pages/docs/StockPage";
import ContractPage from "./pages/docs/ContractPage";
import SOWPage from "./pages/docs/SOWPage";
import TrainingPage from "./pages/docs/TrainingPage";

import DashboardPatrolPage from "./pages/patrol/DashboardPage";
import DashboardTimeAttendancePage from "./pages/timeattendance/DashboardPage";
import DashboardDailyPage from "./pages/daily/DashboardPage";
import DashboardCentralizePage from "./pages/centralize/DashboardPage";
import DashboardIOTPage from "./pages/iot/DashboardPage";

import { useSelector, useDispatch } from "react-redux";

import { getAccount, getAccountLimit } from "./reducers/accountSlice";
import { getAccountService, getAccountLimitService } from "./services/account";
import { setSiteAccount } from "./reducers/siteSlice";
import { getSiteService } from "./services/site";
import { signInWithEmail, signInWithUsername } from "./services/auth";

import { isMobile, isTablet } from "react-device-detect";
import { getEventService, updateEventService } from "./services/event";

import "./App.css";

const modalStyles = {
  mask: {
    backdropFilter: "blur(5px)",
  },
};

const App = () => {
  const [collapsed, setCollapsed] = useState(true);

  const selectedSystem = useSelector((state) => state.system.system);
  const account = useSelector((state) => state.account.account);
  const accountLimit = useSelector((state) => state.account.accountLimit);
  const selectedSite = useSelector((state) => state.site.site);
  const [accountType, setAccountType] = useState("0");

  const [messageApi, contextHolder] = message.useMessage();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isAuth, setIsAuth] = useState(
    localStorage.getItem("account") !== null
  );

  const [event, setEvent] = useState({});

  const dispatch = useDispatch();

  const systemName = {
    1: "vms",
    2: "patrol",
  };

  const handleEventChange = async () => {
    const data = await getEventService(account.account_uuid);
    if (data !== undefined) {
      setEvent(data);
      if (data?.event_name === "calling" && data?.event_status === false) {
        const audioElement = document.getElementById("player");
        audioElement.muted = true;
        audioElement.muted = false;
        audioElement.play();
      }
    }
  };

  const handleEventUpdate = async () => {
    await updateEventService(account.account_uuid);
    await localStorage.setItem("system", 5);
    await window.open(`/`);
  };

  useEffect(() => {
    if (account?.account_uuid) {
      handleEventChange();
    }
    setInterval(() => {
      if (account?.account_uuid) {
        handleEventChange();
      }
    }, 5000);
  }, [account]);

  const handleAccountChange = (value) => {
    dispatch(getAccount(value));
  };

  const handleAccountLimitChange = (value) => {
    dispatch(getAccountLimit(value));
  };

  const handleSignIn = async () => {
    if (localStorage.getItem("accountType") === "1") {
      const data = await signInWithEmail(email, password);
      if (data.user !== null) {
        await getAccountService(data.user.id).then((data) => {
          handleAccountChange(data);
          getSiteService(data.account_uuid).then((data) => {
            dispatch(setSiteAccount(data));
            localStorage.setItem("site", JSON.stringify(data[0]["site_uuid"]));
            getAccountLimitService(data[0]["account_uuid"]).then((data) => {
              handleAccountLimitChange(data);
              window.location.reload();
            });
          });
        });

        setIsAuth(true);
      } else {
        messageApi.open({
          type: "error",
          content: "รหัสผ่านหรือรหัสผ่านไม่ถูกต้อง",
        });
      }
    } else if (localStorage.getItem("accountType") === "0") {
      const data = await signInWithUsername(email, password);
      if (data.length > 0) {
        localStorage.setItem("account", JSON.stringify(data[0]));
        localStorage.setItem("site", JSON.stringify(data[0]["site_uuid"]));
        setIsAuth(true);
        window.location.reload();
      } else {
        messageApi.open({
          type: "error",
          content: "รหัสผ่านหรือรหัสผ่านไม่ถูกต้อง",
        });
      }
    } else {
      messageApi.open({
        type: "error",
        content: "กรุณาเลือกประเภทบัญชี",
      });
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("account") !== null &&
      localStorage.getItem("accountType") === "1"
    ) {
      getAccountService(
        JSON.parse(localStorage.getItem("account"))["account_uuid"]
      ).then((data) => {
        handleAccountChange(data);
        getSiteService(data.account_uuid).then((data) => {
          dispatch(setSiteAccount(data));
        });
      });
      getAccountLimitService(
        JSON.parse(localStorage.getItem("account"))["account_uuid"]
      ).then((data) => {
        handleAccountLimitChange(data);
      });
      setIsAuth(true);
    } else if (
      localStorage.getItem("account") !== null &&
      localStorage.getItem("accountType") === "0"
    ) {
      const data = JSON.parse(localStorage.getItem("account"));
      handleAccountChange(data);
      dispatch(
        setSiteAccount([
          {
            site_name: data["site_name"],
            site_uuid: data["site_uuid"],
          },
        ])
      );
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("system")) {
      localStorage.setItem("system", 2);
    }
  }, []);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Router>
      {contextHolder}
      <Modal
        id="player-modal"
        open={event?.event_name === "calling" && event?.event_status === false}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
      >
        <div className="flex justify-center items-center text-[18px]">
          มีการเรียกติดต่อ
        </div>
        <div className="flex justify-center items-center mt-2">
          จากหน่วยงาน: {event?.event_site}
        </div>
        <div className="flex justify-center items-center">
          <img width={500} src="calling.gif" />
        </div>

        <audio
          id="player"
          controls
          playsInline
          autoPlay
          style={{ display: "none" }}
        >
          <source
            src="https://woeexpeqouhtojnuyueg.supabase.co/storage/v1/object/public/siamai/temp/calling.mp3"
            type="audio/mp3"
          />
        </audio>

        <div className="flex justify-center items-center">
          <Button
            style={{
              width: "100%",
              height: "50px",
            }}
            type="primary"
            danger
            onClick={handleEventUpdate}
          >
            รับสาย
          </Button>
        </div>
      </Modal>
      <Modal
        open={isMobile && !isTablet}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
      >
        <div className="flex justify-center items-center text-[18px]">
          ปัจจุบันระบบรองรับการใช้งานผ่าน PC เท่านั้น
        </div>
        <div className="flex justify-center items-center">
          ขอบคุณที่ใช้งานระบบของเรา
        </div>
      </Modal>
      <Modal
        title="เข้าสู่ระบบบริหารจัดการ"
        closeIcon={false}
        okText="เข้าสู่ระบบ"
        cancelText="ยกเลิก"
        open={!isAuth && (!isMobile || isTablet)}
        onOk={handleSignIn}
        styles={modalStyles}
      >
        <p>กรุณาเข้าสู่ระบบเพื่อใช้งาน</p>
        <div className="grid grid-cols-4 gap-2 items-center">
          <p className="col-span-1 subpixel-antialiased">เลือกประเภทบัญชี</p>
          <Select
            className="col-span-3"
            defaultValue="0"
            onSelect={(value) => {
              localStorage.setItem("accountType", value);
              setAccountType(value);
            }}
            onChange={(value) => {
              localStorage.setItem("accountType", value);
              setAccountType(value);
            }}
            options={[
              { value: "0", label: "สำหรับหน่วยงาน" },
              { value: "1", label: "สำหรับบริษัท" },
            ]}
          />
        </div>
        <div className="grid grid-cols-1">
          <Input
            placeholder="ชื่อผู้ใช้"
            size="large"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <Input.Password
            className="mt-2"
            size="large"
            placeholder="รหัสผ่าน"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {/* {accountType === "0" && (
            <iframe
              className="mt-4"
              width="100%"
              height="315px"
              src="https://www.youtube.com/embed/SjpC7f9TnN0?si=RiKOgLtZe6n0JKod"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )}
          {accountType === "1" && (
            <iframe
              className="mt-4"
              width="100%"
              height="315px"
              src="https://www.youtube.com/embed/R4iswin5owk?si=9Gf9FEmE7PKDN6eY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )} */}
        </div>
      </Modal>
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar collapsed={collapsed} />
        <Layout>
          {(!isMobile || isTablet) && (
            <Header
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              colorBgContainer={colorBgContainer}
            />
          )}

          <Routes>
            {isMobile && !isTablet && <Route path="/" element={OnlyPC} />}

            {(!isMobile || isTablet) && selectedSystem === 1 && (
              <Route
                path="/"
                element={
                  <DashboardVMSPage
                    colorBgContainer={colorBgContainer}
                    borderRadiusLG={borderRadiusLG}
                  />
                }
              />
            )}

            {(!isMobile || isTablet) && selectedSystem === 2 && (
              <Route
                path="/"
                element={
                  <DashboardPatrolPage
                    colorBgContainer={colorBgContainer}
                    borderRadiusLG={borderRadiusLG}
                  />
                }
              />
            )}

            {(!isMobile || isTablet) && selectedSystem === 3 && (
              <Route
                path="/"
                element={
                  <DashboardTimeAttendancePage
                    colorBgContainer={colorBgContainer}
                    borderRadiusLG={borderRadiusLG}
                  />
                }
              />
            )}

            {(!isMobile || isTablet) && selectedSystem === 4 && (
              <Route
                path="/"
                element={
                  <DashboardDailyPage
                    colorBgContainer={colorBgContainer}
                    borderRadiusLG={borderRadiusLG}
                  />
                }
              />
            )}

            {(!isMobile || isTablet) && selectedSystem === 5 && (
              <Route
                path="/"
                element={
                  <DashboardCentralizePage
                    colorBgContainer={colorBgContainer}
                    borderRadiusLG={borderRadiusLG}
                  />
                }
              />
            )}

            {(!isMobile || isTablet) && selectedSystem === 6 && (
              <Route
                path="/"
                element={
                  <DashboardIOTPage
                    colorBgContainer={colorBgContainer}
                    borderRadiusLG={borderRadiusLG}
                  />
                }
              />
            )}

            <Route
              path="/docs"
              element={
                <IndexDocsPage
                  account={account}
                  colorBgContainer={colorBgContainer}
                  borderRadiusLG={borderRadiusLG}
                />
              }
            />

            <Route
              path="/docs/employee"
              element={
                <EmployeePage
                  colorBgContainer={colorBgContainer}
                  borderRadiusLG={borderRadiusLG}
                  site_uuid={selectedSite}
                />
              }
            />

            <Route
              path="/docs/stock"
              element={
                <StockPage
                  colorBgContainer={colorBgContainer}
                  borderRadiusLG={borderRadiusLG}
                  site_uuid={selectedSite}
                />
              }
            />

            <Route
              path="/docs/contract"
              element={
                <ContractPage
                  colorBgContainer={colorBgContainer}
                  borderRadiusLG={borderRadiusLG}
                  site_uuid={selectedSite}
                />
              }
            />

            <Route
              path="/docs/sow"
              element={
                <SOWPage
                  colorBgContainer={colorBgContainer}
                  borderRadiusLG={borderRadiusLG}
                  site_uuid={selectedSite}
                />
              }
            />

            <Route
              path="/docs/training"
              element={
                <TrainingPage
                  colorBgContainer={colorBgContainer}
                  borderRadiusLG={borderRadiusLG}
                  site_uuid={selectedSite}
                />
              }
            />

            <Route
              path="/profile"
              element={
                <ProfilePage
                  account={account}
                  colorBgContainer={colorBgContainer}
                  borderRadiusLG={borderRadiusLG}
                />
              }
            />

            {localStorage.getItem("accountType") === "1" && (
              <>
                <Route
                  path="/system/sites"
                  element={
                    <SitePage
                      account={account}
                      accountLimit={accountLimit}
                      colorBgContainer={colorBgContainer}
                      borderRadiusLG={borderRadiusLG}
                    />
                  }
                />
                <Route
                  path="/system/program"
                  element={
                    selectedSystem === 1 ? (
                      <ProgramVMSPage
                        system={systemName[selectedSystem]}
                        site_uuid={selectedSite}
                        colorBgContainer={colorBgContainer}
                        borderRadiusLG={borderRadiusLG}
                      />
                    ) : (
                      <ProgramPatrolPage
                        accountLimit={accountLimit}
                        site_uuid={selectedSite}
                        colorBgContainer={colorBgContainer}
                        borderRadiusLG={borderRadiusLG}
                      />
                    )
                  }
                />
                <Route
                  path="/system/cycle"
                  element={
                    selectedSystem === 2 && (
                      <CyclePatrolPage
                        system={systemName[selectedSystem]}
                        site_uuid={selectedSite}
                        colorBgContainer={colorBgContainer}
                        borderRadiusLG={borderRadiusLG}
                      />
                    )
                  }
                />
                <Route
                  path="/system/subcontract"
                  element={
                    <SubContractVMSPage
                      system={systemName[selectedSystem]}
                      site_uuid={selectedSite}
                      accountLimit={accountLimit}
                      colorBgContainer={colorBgContainer}
                      borderRadiusLG={borderRadiusLG}
                    />
                  }
                />
                <Route
                  path="/system/devices"
                  element={
                    <DeviceVMSPage
                      system={systemName[selectedSystem]}
                      site_uuid={selectedSite}
                      accountLimit={accountLimit}
                      colorBgContainer={colorBgContainer}
                      borderRadiusLG={borderRadiusLG}
                    />
                  }
                />
                <Route
                  path="/system/device-noti"
                  element={
                    <DeviceNotiPage
                      system={systemName[selectedSystem]}
                      site_uuid={selectedSite}
                      accountLimit={accountLimit}
                      colorBgContainer={colorBgContainer}
                      borderRadiusLG={borderRadiusLG}
                    />
                  }
                />
                <Route
                  path="/system/notify"
                  element={
                    <NotifyPage
                      system={systemName[selectedSystem]}
                      site_uuid={selectedSite}
                      colorBgContainer={colorBgContainer}
                      borderRadiusLG={borderRadiusLG}
                    />
                  }
                />
              </>
            )}
          </Routes>
          <Footer />
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
