import React, { useState, useEffect } from "react";
import { Layout, Input, Button, Modal, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotiService,
  setNotiService,
  updateNotiService,
} from "../services/noti";
import { setNoti } from "../reducers/notiSlice";
const { Content: AntdContent } = Layout;

const ContentComp = ({
  system,
  site_uuid,
  colorBgContainer,
  borderRadiusLG,
}) => {
  const dispatch = useDispatch();
  const noti = useSelector((state) => state.noti.noti);
  const [loading, setLoading] = useState(false);

  const handleNotiChange = async () => {
    setLoading(true);
    const data = await getNotiService(system, site_uuid);
    dispatch(setNoti(data));
    setLoading(false);
  };

  const handleAddNoti = async () => {
    setLoading(true);
    if (noti?.created_at) {
      await updateNotiService(system, noti);
    } else {
      await setNotiService(system, noti);
    }

    await handleNotiChange();
    setLoading(false);
  };

  useEffect(() => {
    handleNotiChange();
  }, [site_uuid, system]);

  return (
    <AntdContent
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      {/* <Modal
        title="คู่มือการใช้งาน"
        style={{ top: "45%", left: "28%" }}
        width={600}
        open={isModalOpen}
        onCancel={handleModalClose}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/kvjPEFbsM_Q?si=VZe6FoiyDrRxjQP6"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal> */}
      <div className="grid grid-cols-1 gap-1 justify-center">
        <p className="subpixel-antialiased text-base font-bold">โหมดการแจ้งเตือน</p>
        <Select
          value={noti?.mode}
          onChange={(value) => {
            dispatch(setNoti({ ...noti, mode: value }));
          }}
          options={[
            { value: "direct", label: "ส่งถึงผู้ว่าจ้างทันที" },
            { value: "indirect", label: "ต้องได้รับการอนุมัติจากห้องปฏิบัติการ" },
          ]}
          style={{
            width: "300px",
          }}
          placeholder="โหมดการแจ้งเตือน"
        />
        <p className="subpixel-antialiased text-base font-bold">Line Token</p>
        <Input
          value={noti?.line}
          style={{
            width: "300px",
          }}
          placeholder="line"
          onChange={(e) => {
            dispatch(
              setNoti({ ...noti, line: e.target.value, site_uuid: site_uuid })
            );
          }}
        />

        <p className="subpixel-antialiased text-base font-bold">Telegram Token</p>
        <Input
          value={noti?.telegram_token}
          style={{
            width: "300px",
          }}
          placeholder="Telegram Bot Token"
          onChange={(e) => {
            dispatch(
              setNoti({ ...noti, telegram_token: e.target.value, site_uuid: site_uuid })
            );
          }}
        />

        <p className="subpixel-antialiased text-base font-bold">Telegram Chat ID</p>
        <Input
          value={noti?.telegram_chat_id}
          style={{
            width: "300px",
          }}
          placeholder="Telegram Chat ID"
          onChange={(e) => {
            dispatch(
              setNoti({ ...noti, telegram_chat_id: e.target.value, site_uuid: site_uuid })
            );
          }}
        />

        <p className="subpixel-antialiased text-base font-bold">Mail</p>
        <Input
          value={noti?.mail1}
          style={{
            width: "300px",
          }}
          placeholder="mail (หลัก)"
          onChange={(e) => {
            dispatch(
              setNoti({ ...noti, mail1: e.target.value, site_uuid: site_uuid })
            );
          }}
        />
        <Input
          value={noti?.mail2}
          style={{
            width: "300px",
          }}
          placeholder="mail (สำรอง)"
          onChange={(e) => {
            dispatch(
              setNoti({ ...noti, mail2: e.target.value, site_uuid: site_uuid })
            );
          }}
        />

        {noti?.created_at ? (
          <Button
            className="mt-4"
            style={{
              width: "300px",
            }}
            type="primary"
            onClick={handleAddNoti}
            disabled={loading}
          >
            อัพเดตข้อมูล
          </Button>
        ) : (
          <Button
            className="mt-4"
            style={{
              width: "300px",
            }}
            type="primary"
            onClick={handleAddNoti}
            disabled={loading}
          >
            บันทึกข้อมูล
          </Button>
        )}
      </div>
    </AntdContent>
  );
};

export default ContentComp;
