import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
    "https://woeexpeqouhtojnuyueg.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getDeviceNotiService = async (site_uuid) => {
    const { data } = await supabase
        .from(`site_device_noti`)
        .select()
        .eq("site_uuid", site_uuid)
        .order("id");
    return await data;
};

export const setDeviceNotiService = async (payload) => {
    await supabase.from(`site_device_noti`).insert(payload);
};

export const updateDeviceNotiService = async (payload) => {
    await supabase.from(`site_device_noti`).update(payload).eq("id", payload.id);
};

export const deleteDeviceNotiService = async (system, id) => {
    await supabase.from(`site_device_noti`).delete().eq("id", id);
};

