import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Switch } from "antd";
import { updateDeviceNotiService, getDeviceNotiService } from "../services/deviceNoti";
import { useDispatch } from "react-redux";
import { setDeviceNoti } from "../reducers/deviceNotiSlice";

const DeviceNotiEdit = ({ payload }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [site_uuid, setSiteUUID] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [vms, setVms] = useState(false);
  const [patrol, setPatrol] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSiteUUID(payload.site_uuid);
    setName(payload.name);
    setDescription(payload.description);
    setVms(payload.vms);
    setPatrol(payload.patrol);
  }, [payload]);

  const handleDeviceNotiChange = async () => {
    setLoading(true);
    const data = await getDeviceNotiService(site_uuid);
    dispatch(setDeviceNoti(data));
    setLoading(false);
  };

  const handleUpdateDeviceNoti = async () => {
    setLoading(true);
    await updateDeviceNotiService({
      id: payload.id,
      site_uuid: site_uuid,
      name: name,
      description: description,
      vms: vms,
      patrol: patrol,
    });
    await handleDeviceNotiChange();
    setLoading(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    handleUpdateDeviceNoti();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        แก้ไขการแจ้งเตือน
      </Button>
      <Modal
        title="แก้ไขการแจ้งเตือน"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>ชื่อ:</p>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="ชื่อ"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={4}
          placeholder="หมายเหตุ"
        />
        <p>ประเภทอุปกรณ์:</p>
        <div style={{ marginBottom: '16px' }}>
          <Switch
            checked={vms}
            onChange={setVms}
            style={{ marginRight: '16px' }}
          />
          VMS
          <Switch
            checked={patrol}
            onChange={setPatrol}
          />
          Patrol
        </div>
      </Modal>
    </>
  );
};

export default DeviceNotiEdit;
