import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviceNoti: [],
};

const devicenotiSlice = createSlice({
  name: "devicenoti",
  initialState,
  reducers: {
    setDeviceNoti: (state, action) => {
      state.deviceNoti = action.payload;
    },
  },
});

export const { setDeviceNoti } = devicenotiSlice.actions;
export default devicenotiSlice.reducer;