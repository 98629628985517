import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Select, Switch } from "antd";
import {
  setDeviceNotiService,
  getDeviceNotiService,
} from "../services/deviceNoti";
import { useDispatch } from "react-redux";
import { setDeviceNoti } from "../reducers/deviceNotiSlice";
import { message } from "antd";

const DeviceNotiCreate = ({ site_uuid, system, accountLimit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [vms, setVms] = useState(false);
  const [patrol, setPatrol] = useState(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleSiteAccountChange = async () => {
    setLoading(true);
    const data = await getDeviceNotiService(site_uuid);
    dispatch(setDeviceNoti(data));
    setLoading(false);
  };

  const handleAddDevice = async () => {
    setLoading(true);
    let limit = 0;
    if (system === "vms") {
      limit = accountLimit.create_vms_device_limit;
    } else {
      limit = accountLimit.create_patrol_device_limit;
    }

    try {
      await setDeviceNotiService({
        site_uuid: site_uuid,
        name: name,
        description: description,
        vms: vms,
        patrol: patrol,
      });
      await handleSiteAccountChange();
      messageApi.open({
        type: "success",
        content: "เพิ่มอุปกรณ์แจ้งเตือนสำเร็จ",
      });
      setIsModalOpen(false);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "เกิดข้อผิดพลาดในการเพิ่มอุปกรณ์แจ้งเตือน",
      });
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setName("");
    setDescription("");
    setVms(false);
    setPatrol(false);
  };

  useEffect(() => {
    handleSiteAccountChange()
  }, [site_uuid, system]);

  return (
    <>
      <Button loading={loading} type="primary" onClick={showModal}>
        เพิ่มอุปกรณ์แจ้งเตือน
      </Button>
      {contextHolder}
      <Modal
        title="สร้างอุปกรณ์แจ้งเตือน"
        open={isModalOpen}
        onOk={handleAddDevice}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <p>ชื่ออุปกรณ์:</p>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="ชื่ออุปกรณ์"
        />
        <p>หมายเหตุ:</p>
        <Input.TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={4}
          placeholder="หมายเหตุ"
        />
        <p>ประเภทอุปกรณ์:</p>
        <div style={{ marginBottom: '16px' }}>
          <Switch
            checked={vms}
            onChange={setVms}
            style={{ marginRight: '16px' }}
          />
          VMS
          <Switch
            checked={patrol}
            onChange={setPatrol}
          />
          Patrol
        </div>
      </Modal>
    </>
  );
};

export default DeviceNotiCreate;
