import React, { useState, useEffect } from "react";
import { Layout, Table, Modal } from "antd";
import { getDeviceNotiService } from "../services/deviceNoti";
import { useSelector, useDispatch } from "react-redux";
import { setDeviceNoti } from "../reducers/deviceNotiSlice";

import DeviceNotiCreate from "../components/DeviceNotiCreate";
import DeviceNotiEdit from "../components/DeviceNotiEdit";

import Delete from "../components/Delete";
import { deleteDeviceNotiService } from "../services/deviceNoti";

import QRCodeCanvas from "qrcode.react";

const { Content: AntdContent } = Layout;

const systemName = {
    1: "vms",
    2: "patrol",
};

const columns = [
    {
        title: "ชื่ออุปกรณ์",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Access Key",
        dataIndex: "token",
        key: "token",
        render: (_, { token }) => (
            <>
                <QRCodeCanvas value={token} level={"L"} size={248} />
            </>
        ),
    },
    {
        title: "การใช้งาน",
        dataIndex: "link",
        key: "link",
        render: (_, { token }) => (
            <>
                <a
                    href={`https://app-noti-siamai.web.app/${systemName[JSON.parse(localStorage.getItem("system"))]
                        }?token=${token}`}
                    onClick={(event) => {
                        event.preventDefault();
                        const url = `https://app-noti-siamai.web.app/${systemName[JSON.parse(localStorage.getItem("system"))]
                            }?token=${token}`;
                        navigator.clipboard
                            .writeText(url)
                            .then(() => {
                                //console.log("URL copied to clipboard:", url);
                                // แสดงข้อความหรือประกาศว่าลิงก์ถูกคัดลอกแล้ว
                                alert("ลิงก์ถูกคัดลอกแล้ว: " + url);
                            })
                            .catch((error) => {
                                console.error("Error copying to clipboard: ", error);
                                // แสดงข้อความหรือประกาศว่ามีข้อผิดพลาดเกิดขึ้นในขณะคัดลอก
                                alert("เกิดข้อผิดพลาดในการคัดลอกลิงก์: " + error);
                            });
                    }}
                >
                    คลิกเพื่อคัดลอกลิงก์
                </a>
            </>
        ),
    },
    {
        title: "ตั้งค่า",
        dataIndex: "",
        key: "x",
        render: (_, payload) => {
            return (
                <div className="space-x-2">
                    <DeviceNotiEdit
                        payload={payload}
                    />
                    <Delete
                        system={systemName[JSON.parse(localStorage.getItem("system"))]}
                        func={deleteDeviceNotiService}
                        id={payload.id}
                    />
                </div>
            );
        },
    },
];

const DeviceNoti = ({
    system,
    colorBgContainer,
    borderRadiusLG,
    site_uuid,
    accountLimit,
}) => {
    const dispatch = useDispatch();
    const deviceNoti = useSelector((state) => state.deviceNoti.deviceNoti);

    const [loading, setLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(
        !localStorage.getItem("device_youtube")
    );

    const handleModalClose = () => {
        localStorage.setItem("device_youtube", true);
        setIsModalOpen(false);
    };

    const handleDeviceChange = async () => {
        setLoading(true);
        const data = await getDeviceNotiService(site_uuid);
        dispatch(setDeviceNoti(data));
        setLoading(false);
    };

    useEffect(() => {
        handleDeviceChange();
    }, [site_uuid, system]);

    return (
        <AntdContent
            style={{
                margin: "24px 16px",
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
            }}
        >
            <div className="flex justify-between">
                <div className="text-2xl">รายชื่ออุปกรณ์</div>
                <DeviceNotiCreate
                    system={system}
                    site_uuid={site_uuid}
                    accountLimit={accountLimit}
                />
            </div>

            {/* <Modal
        title="คู่มือการใช้งาน"
        style={{ top: "45%", left: "28%" }}
        width={600}
        open={isModalOpen}
        onCancel={handleModalClose}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/T2VNqGa7n2E?si=NPP3IvfNtHr2aezi"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal> */}

            <Table
                className="mt-4"
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => (
                        <p
                            style={{
                                margin: 0,
                            }}
                        >
                            {record.description}
                        </p>
                    ),
                }}
                dataSource={deviceNoti}
                rowKey="id"
            />
        </AntdContent>
    );
};

export default DeviceNoti;
